import { ReactNode } from "react";

import { api } from "@/app/redux/api";
import { RequestPaginationParams } from "@/types/request-params";

import {
  EventOverallKpiSchema,
  EventRequestType,
  EventSchema,
  EventType,
  ImportScheduleRequestType,
  ImportScheduleResponseSchema,
  ImportScheduleType,
} from "../pages/Events/event.schema";
import { EventStatus, EventsOverallKPIResponse, EventsResponse } from "../pages/Events/types";

export const eventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<
      EventsResponse,
      RequestPaginationParams & {
        status?: EventStatus;
        brandId?: number;
        projectId?: number;
        eventType?: string;
      }
    >({
      query: ({ page, limit, status, query, brandId, projectId, eventType }) => ({
        url: "events",
        params: {
          page,
          limit,
          ...(status && { status: status.toLowerCase() }),
          ...(query && { query }),
          ...(brandId && { brandId }),
          ...(projectId && { projectId }),
          ...(eventType && { eventType }),
        },
        responseSchema: EventSchema,
      }),
      providesTags: [{ type: "Events", id: "OVERVIEW" }],
    }),
    getOverallKPIsForEvents: builder.query<
      EventsOverallKPIResponse,
      { brandId?: number; projectId?: number }
    >({
      query: ({ brandId, projectId }) => ({
        url: "kpi/overall",
        params: {
          ...(brandId && { brandId }),
          ...(projectId && { projectId }),
        },
        responseSchema: EventOverallKpiSchema,
      }),
      providesTags: [{ type: "Events", id: "OVERVIEW" }],
    }),
    createEvent: builder.mutation<EventsResponse, EventRequestType>({
      query: (body) => ({
        url: "events",
        method: "POST",
        body,
        responseSchema: EventSchema,
      }),
      invalidatesTags: [{ type: "Events", id: "OVERVIEW" }],
    }),
    getEventById: builder.query<EventType, number>({
      query: (id) => ({
        url: `events/${id}`,
        responseSchema: EventSchema,
      }),
      providesTags: [{ type: "Events", id: "DETAIL" }],
    }),
    getEventKpi: builder.query<
      EventType,
      {
        eventId: number;
        eventDateId: number | null;
        dashboard: ReactNode;
        dateRangeStart?: string;
        dateRangeEnd?: string;
      }
    >({
      query: ({ eventId, eventDateId, dashboard, dateRangeStart, dateRangeEnd }) => ({
        url: "kpi",
        params: {
          eventId,
          dashboard,
          ...(eventDateId && { eventDateId }),
          ...(dateRangeStart && { dateRangeStart }),
          ...(dateRangeEnd && { dateRangeEnd }),
        },
        responseSchema: EventSchema,
      }),
    }),
    updateEvent: builder.mutation<EventType, { id: number; body: Partial<EventRequestType> }>({
      query: ({ id, body }) => ({
        url: `events/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: "Events", id: "OVERVIEW" }],
    }),
    getRegions: builder.query<{ data: string[] }, void>({
      query: () => ({
        url: "events/regions",
      }),
    }),
    importSchedule: builder.mutation<ImportScheduleType, ImportScheduleRequestType>({
      query: (body) => ({
        url: `events/import-schedule/${body.id}`,
        method: "POST",
        body: body.excelFile,
        // requestSchema: ImportScheduleBodySchema,
        responseSchema: ImportScheduleResponseSchema,
      }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetOverallKPIsForEventsQuery,
  useLazyGetOverallKPIsForEventsQuery,
  useCreateEventMutation,
  useGetEventByIdQuery,
  useGetEventKpiQuery,
  useUpdateEventMutation,
  useGetRegionsQuery,
  useImportScheduleMutation,
} = eventsApi;
export const eventsApiReducer = eventsApi.reducer;
export const eventsApiMiddleware = eventsApi.middleware;
