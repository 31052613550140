import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { EventDatesSchema, KpiSchema } from "./../Events/event.schema";

const ProjectsListBrandSchema = z.object({
  brandId: z.number(),
  projectId: z.number(),
  name: z.string(),
  image: z.string(),
  companyId: z.number(),
});

const ProjectSchema = z.object({
  id: z.number(),
  name: z.string(),
  events: z.string().array(),
  brands: z.array(ProjectsListBrandSchema),
  companyId: z.number(),
});

const ProjectsListResponseSchema = z.object({
  data: z.array(ProjectSchema),
  meta: MetaDataSchema,
});

export const ProjectByBrandKPIsResponseSchema = z.object({
  projectId: z.number(),
  brandId: z.number(),
  projectName: z.string(),
  name: z.string(),
  eventDates: z.array(EventDatesSchema),
  expectedVisitors: z.number(),
  actualVisitors: z.number(),
  image: z.string(),
  data: z.array(KpiSchema),
});

export type ProjectBrandType = z.infer<typeof ProjectsListBrandSchema>;
export type ProjectType = z.infer<typeof ProjectSchema>;
export type ProjectsListResponseType = z.infer<typeof ProjectsListResponseSchema>;
export type ProjectByBrandKPIsResponseType = z.infer<typeof ProjectByBrandKPIsResponseSchema>;
