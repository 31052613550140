import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTE_CONFIG } from "@/app/routes/config";
import { useAppSelector } from "@/app/redux/hooks";
import { MainLayout } from "@/components";

import { authSelector } from "../Public/redux/auth.slice";
import { NotFound } from "../NotFound/NotFound";
import { Brands, Companies, Events, Users, Modules, DataSources } from "./pages";
import { AdminAuth } from "./pages/AdminAuth";
import { ProjectReporting } from "./pages/ProjectReporting/ProjectReporting";

const { LOGIN } = ROUTE_CONFIG;

export const Private: FunctionComponent = () => {
  const { user } = useAppSelector(authSelector);

  if (!user) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <MainLayout>
      <Routes>
        <Route element={<Events />} path="events/*" />
        <Route element={<ProjectReporting />} path="project-reporting/*" />
        <Route element={<AdminAuth />}>
          <Route element={<Users />} path="users" />
          <Route element={<Brands />} path="brands" />
          <Route element={<Modules />} path="modules/*" />
          <Route element={<Companies />} path="companies/*" />
          <Route element={<DataSources />} path="data-sources" />
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>
    </MainLayout>
  );
};
