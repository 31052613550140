import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ToastContainer, Zoom } from "react-toastify";
import { Helmet } from "react-helmet";

import "react-toastify/dist/ReactToastify.css";

import { theme } from "./theme/theme";
import { bSureTheme } from "./theme/bSureTheme";
import { Routing } from "./app/routes";
import { store } from "./app/redux";

import "@app/i18n";
import { ProductVersion } from "./types";
import { useProductVersion } from "./app/hooks/useProductVersion";

function App() {
  const currentProductVersion = useProductVersion();
  const title =
    currentProductVersion === ProductVersion.BSURE ? "BSURE Dashboard" : "FRET Dashboard";

  return (
    <Provider store={store}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ToastContainer
        closeOnClick
        draggable
        hideProgressBar
        autoClose={3000}
        closeButton={false}
        newestOnTop={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position="bottom-left"
        rtl={false}
        transition={Zoom}
      />
      <ThemeProvider theme={currentProductVersion === ProductVersion.BSURE ? bSureTheme : theme}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
