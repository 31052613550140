import { FunctionComponent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, FormControl, InputAdornment, TextField } from "@mui/material";
import { DateRange, PickerModal } from "mui-daterange-picker-plus";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { BackLink, SelectField, SettingsButton } from "@/components";
import { formatInTimeZone, convertHourToMinutes } from "@/app/utils";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { Dashboard, PermissionRoles } from "@/enum";
import { useWindowSize } from "@/app/hooks/useWindowSize";
import { ContextTooltip, TooltipType } from "@/components/ContextTooltip/ContextTooltip";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";

import { KpiTargetTypeSwitch } from "../../components/KpiTargetTypeSwitch";
import { EventDatesType } from "../../../event.schema";
import { RibbonMenu } from "../../../../../../../components/RibbonMenu/RibbonMenu";
import { EventTypes } from "../../../types";

interface SettingsSectionProps {
  dates: EventDatesType[];
  handleEventDateChange: (id: number | null) => void;
  handleEventDateRangeChange: (dateRange: DateRange | null) => void;
  handleEventTimeBracketChange: (startingMinute: number | null) => void;
  selectedDashboard: string | null;
}

export const SettingsSection: FunctionComponent<SettingsSectionProps> = ({
  dates,
  handleEventDateChange,
  handleEventDateRangeChange,
  handleEventTimeBracketChange,
  selectedDashboard,
}) => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.${key}.description`), [t]);

  const is2ndKpiFeatureActive = useIsFeatureActive(Features.SECONDARY_PLANNED_KPI); // TECH DEBT - BA-706
  const { eventDetails: event } = useAppSelector(eventsSelector);
  const { width } = useWindowSize();

  const isFestivalEvent =
    event?.eventType === EventTypes.FESTIVAL || event?.eventType === EventTypes.PLOOM_FESTIVAL;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleDateRangePickerOpen = (event: MouseEvent<HTMLElement>) => {
    setDateRangePickerOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleDateRangePickerClose = () => {
    setSelectedDateRange(null);
    setDateRangePickerOpen(false);
  };

  const handleSetDateRangeOnSubmit = (dateRange: DateRange) => {
    setSelectedDateRange(dateRange);
    setDateRangePickerOpen(false);
  };

  const { register, watch } = useForm({
    defaultValues: {
      eventDateId: "DEFAULT",
      pickedTimeBracket: "DEFAULT",
      locationIds: [],
      teamId: "DEFAULT",
      eventFromTillTimes: [{ fromTime: "from", tillTime: "till" }],
    },
  });

  const { eventDateId, pickedTimeBracket } = watch();

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const dashboardTooltip = useMemo(() => {
    switch (selectedDashboard) {
      case Dashboard.SALES:
        return (
          <ContextTooltip
            isOpen={tooltipOpen}
            title={ts("salesKPIs")}
            type={TooltipType.INFO}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          />
        );
      case Dashboard.DOI:
        return (
          <ContextTooltip
            isOpen={tooltipOpen}
            title={ts("doiKPIs")}
            type={TooltipType.INFO}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          />
        );

      default:
        return (
          <ContextTooltip
            isOpen={tooltipOpen}
            title={t("events.dashboardsTooltipTitle")}
            type={TooltipType.INFO}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          />
        );
    }
  }, [selectedDashboard, t, ts, tooltipOpen]);

  const formatHour = (hour: number): string => {
    return hour < 10 ? `0${hour}` : `${hour}`;
  };

  //This function is part of the hourly filter logic, which is used only for Promoter dashboard. Could be added to utils if it's needed in multiple cases.
  const generateHourBrackets = useCallback(
    (startHour: string, endHour: string): { text: string; value: string | number }[] => {
      const hourBrackets: { text: string; value: string | number }[] = [];

      const startMinutes = convertHourToMinutes(startHour);
      const endMinutes = convertHourToMinutes(endHour);

      const adjustedEndMinutes = endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

      for (let i = startMinutes; i < adjustedEndMinutes; i += 60) {
        const startBracketHour = Math.floor(i / 60) % 24;
        const endBracketHour = Math.floor((i + 60) / 60) % 24;

        const text = `${formatHour(startBracketHour)}:00-${formatHour(endBracketHour)}:00`;
        const value = i;

        hourBrackets.push({ text, value });
      }

      return hourBrackets;
    },
    []
  );

  //TODO This filters logic will be uncomment after the ETL changes are tested and merged
  //TODO Set logic to get locations and teams data when BE is ready
  // const eventTeamsOptions = [
  //   //MOCKED OBJECT (To be Deleted)
  //   {
  //     text: "Team 1",
  //     value: 1,
  //   },
  //   {
  //     text: "Team 2",
  //     value: 2,
  //   },
  //   {
  //     text: "Team 3",
  //     value: 3,
  //   },
  // ];
  // const eventLocationsOptions = [
  //   //MOCKED OBJECT (To be Deleted)
  //   {
  //     text: "Location 1",
  //     value: "loc1",
  //   },
  //   {
  //     text: "Location 2",
  //     value: "loc2",
  //   },
  //   {
  //     text: "Location 3",
  //     value: "loc3",
  //   },
  // ];

  const eventDateOptions = useMemo(() => {
    return dates
      ?.slice()
      .reverse()
      .map((date, i) => {
        return {
          text: `${t("basics.day")} ${dates.length - i} - ${formatInTimeZone(
            new Date(date.fromDateTime),
            "dd/MM/yyyy"
          )} - ${formatInTimeZone(new Date(date.tillDateTime), "dd/MM/yyyy")}`,
          value: date.id,
        };
      });
  }, [t, dates]);

  const eventTimeOptions = useMemo(() => {
    const selectedDateData = dates?.filter((date) => date.id === +eventDateId).at(0);

    let eventStartHour = "";
    let eventEndHour = "";

    if (selectedDateData) {
      eventStartHour = formatInTimeZone(new Date(selectedDateData.fromDateTime), "HH:mm");
      eventEndHour = formatInTimeZone(new Date(selectedDateData.tillDateTime), "HH:mm");
    }

    return generateHourBrackets(eventStartHour, eventEndHour);
  }, [eventDateId, generateHourBrackets, dates]);

  useEffect(() => {
    if (eventDateId !== "DEFAULT") {
      handleEventDateChange(Number(eventDateId));
    } else {
      handleEventDateChange(null);
    }
  }, [eventDateId, handleEventDateChange]);

  useEffect(() => {
    if (selectedDateRange) {
      handleEventDateRangeChange(selectedDateRange);
    } else {
      handleEventDateRangeChange(null);
    }
  }, [selectedDateRange, handleEventDateRangeChange]);

  useEffect(() => {
    if (pickedTimeBracket !== "DEFAULT") {
      handleEventTimeBracketChange(Number(pickedTimeBracket));
    } else {
      handleEventTimeBracketChange(null);
    }
  }, [pickedTimeBracket, handleEventTimeBracketChange]);

  return (
    <>
      {width < 600 && <RibbonMenu />}
      <Box
        alignItems="center"
        display="flex"
        mb={1.5}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box alignItems="center" display="flex" sx={{ width: { xs: "100%", md: 800 } }}>
          <Box mr={5} sx={{ display: { xs: "none", md: "inline-block" } }}>
            <BackLink destination={ROUTE_CONFIG.EVENTS} text={t("events.eventList")} />
          </Box>
          {isFestivalEvent ? (
            <Box sx={{ width: { xs: "100%", md: 280 }, mr: 1 }}>
              <SelectField
                withDefaultValue
                options={eventDateOptions}
                placeholder={t("events.totalPeriod")}
                {...register("eventDateId")}
                currentValue={eventDateId || "DEFAULT"}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
                maxWidth: { xs: "100%", md: 600 },
              }}
            >
              {/* TODO The FormControl element below includes the Date Range Picker and should be made as separate component if needs to be used in multiple views */}
              <FormControl
                sx={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
                  fontWeight: 600,
                }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ color: "green.main" }}>
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={(input) =>
                    input && input.addEventListener("click", handleDateRangePickerOpen)
                  }
                  placeholder={t("events.totalPeriod")}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      visibility: "hidden",
                      borderRadius: 1,
                    },
                    "& .MuiInputBase-root": {
                      cursor: "pointer",
                      pointerEvents: "none",
                    },
                    "& .MuiInputBase-input": {
                      paddingY: 0,
                      fontSize: 14,
                      height: "47px",
                      cursor: "pointer",
                    },
                  }}
                  value={
                    selectedDateRange
                      ? `${selectedDateRange.startDate?.toLocaleDateString()} - ${selectedDateRange.endDate?.toLocaleDateString()}`
                      : ""
                  }
                  onClick={handleDateRangePickerOpen}
                />
                <PickerModal
                  customProps={{
                    onSubmit: (range: DateRange) => handleSetDateRangeOnSubmit(range),
                    onCloseCallback: handleDateRangePickerClose,
                    RangeSeparatorIcons: {
                      xs: ArrowCircleDownIcon,
                      md: ArrowCircleRightIcon,
                    },
                  }}
                  hideOutsideMonthDays={false}
                  initialDateRange={{
                    startDate: new Date(),
                    endDate: new Date(),
                  }}
                  maxDate={new Date("2025-12-31")} //TODO Change the dates to be dynamic ?
                  minDate={new Date("2023-01-01")} //TODO Change the dates to be dynamic ?
                  modalProps={{
                    open: dateRangePickerOpen,
                    onClose: handleDateRangePickerClose,
                    anchorEl,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                />
              </FormControl>
              {/* TODO This filters logic will be uncomment after the ETL changes are tested and merged */}
              {/* <SelectField
                withDefaultValue
                currentValue={teamId || "DEFAULT"}
                options={eventTeamsOptions || []}
                placeholder={t("terms.selectTeam")}
                {...register("teamId")}
              />

              <MultipleSelectField
                multiple
                currentValue={locationIds}
                options={eventLocationsOptions || []}
                placeholder={t("terms.selectLocation")}
                {...register("locationIds")}
              /> */}
            </Box>
          )}
          {eventDateId !== "DEFAULT" && selectedDashboard === Dashboard.PROMOTER && (
            <Box sx={{ width: { xs: "100%", md: 180 }, mr: 1 }}>
              <SelectField
                withDefaultValue
                options={eventTimeOptions}
                placeholder={t("events.selectHour")}
                {...register("pickedTimeBracket")}
                currentValue={pickedTimeBracket || "DEFAULT"}
              />
            </Box>
          )}
          {width > 600 && dashboardTooltip}
        </Box>
        {width > 600 && <RibbonMenu />}
        <Box
          alignItems="center"
          alignSelf="start"
          display="flex"
          gap={2}
          sx={{ mt: { xs: 1, md: 0 } }}
        >
          {/* TECH DEBT - BA-706 */}
          {is2ndKpiFeatureActive && <KpiTargetTypeSwitch />}
          {useHasUserPermission(PermissionRoles.UPDATE_EVENTS) && <SettingsButton />}
          {width < 600 && dashboardTooltip}
        </Box>
      </Box>
    </>
  );
};
