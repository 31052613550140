import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { HeatmapChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const GiveawaysByModuleHeatmap: FunctionComponent<HeatmapProps> = ({ kpis, isLoading }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const heatmapLabels = useMemo(() => {
    const giveawaysByModules = kpis.find((k) => k.name === KpiTypeEnum.GIVEAWAY_BY_MODULE)?.data;
    const labels: string[] = [];

    if (!giveawaysByModules) {
      return labels;
    }

    for (const i of giveawaysByModules) {
      const moduleName: string = i.specific as string;

      if (!labels.includes(moduleName)) {
        labels.push(moduleName);
      }
    }

    return labels;
  }, [kpis]);

  const heatmapData = useMemo(() => {
    const heatmapValues: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const giveawaysByModules = kpis.find((k) => k.name === KpiTypeEnum.GIVEAWAY_BY_MODULE)?.data;

    if (!giveawaysByModules) {
      return heatmapValues;
    }

    for (const giveawayByModules of giveawaysByModules) {
      const giveawaysByModuleToDisplay = giveawayByModules?.date?.filter((d) => !!d.dim2) ?? [];
      const moduleName = giveawayByModules.specific as unknown as string;

      for (const giveaway of giveawaysByModuleToDisplay) {
        const productName = giveaway.dim2 as unknown as string;
        const foundProduct = heatmapValues.find((v) => v.name === productName);

        if (foundProduct) {
          const foundData = foundProduct.data.find((d) => d.x === moduleName);

          if (!foundData) {
            foundProduct.data.push({
              x: moduleName,
              y: +giveaway.value.toFixed(2),
            });
          } else {
            foundData.y += +giveaway.value.toFixed(2);
          }
        } else {
          const heatmapValuesData = heatmapLabels.map((label) => {
            const y = label === moduleName ? +giveaway.value.toFixed(2) : 0;

            return {
              x: label,
              y,
            };
          });

          heatmapValues.push({
            name: productName,
            data: heatmapValuesData,
          });
        }
      }
    }

    return [...heatmapValues].sort((a, b) => b.name.localeCompare(a.name));
  }, [heatmapLabels, kpis]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h3" variant="mainBold">
          {ts("heatmapGiveawaysByModules")}
        </Typography>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={ts("heatmapGiveawaysByModules").toLowerCase()}
          labels={heatmapLabels}
          rotate={45}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
