import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAppSelector } from "@/app/redux/hooks";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { AuthLayout } from "@/components/Layouts";

import { Login } from "./pages/Login/Login";
import { authSelector } from "./redux/auth.slice";
import { NotFound } from "../NotFound/NotFound";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { SetPassword } from "./pages/SetPassword/SetPassword";
import { Register } from "./pages/Register/Register";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";

const { EVENTS } = ROUTE_CONFIG;

export const Public: FunctionComponent = () => {
  const { user } = useAppSelector(authSelector);

  if (user) {
    return <Navigate to={EVENTS} />;
  }

  return (
    <AuthLayout>
      <Routes>
        <Route element={<Login />} path="login" />
        <Route element={<SetPassword />} path="set-password" />
        <Route element={<ResetPassword />} path="reset-password" />
        <Route element={<Register />} path="register" />
        <Route element={<ForgotPassword />} path="forgot-password" />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </AuthLayout>
  );
};
