import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { HeatmapChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const DoiByAgegroupByGenderHeatmap: FunctionComponent<HeatmapProps> = ({
  kpis,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = useCallback(
    (key: string) => {
      return t(`events.doiKPIs.doiByAgegroupByGender.${key}`);
    },
    [t]
  );

  const heatmapLabels = useMemo(() => {
    const doiByAgegroups = kpis.find(
      (k) => k.name === KpiTypeEnum.NEWSLETTER_DATA_BY_AGEGROUP_BY_GENDER
    )?.data;
    const labels: string[] = [];

    if (!doiByAgegroups) {
      return labels;
    }

    for (const i of doiByAgegroups) {
      const groupName: string = i.specific as string;

      if (!labels.includes(groupName)) {
        labels.push(groupName);
      }
    }

    return labels;
  }, [kpis]);

  const heatmapData = useMemo(() => {
    const heatmapValues: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const doiByAgegroupsByGender = kpis.find(
      (k) => k.name === KpiTypeEnum.NEWSLETTER_DATA_BY_AGEGROUP_BY_GENDER
    )?.data;

    if (!doiByAgegroupsByGender) {
      return heatmapValues;
    }

    for (const doiByAgegroupByGender of doiByAgegroupsByGender) {
      const doiByAgegroupByGenderToDisplay = doiByAgegroupByGender.date.filter((d) => !!d.dim2);
      const ageGroupName = doiByAgegroupByGender.specific as unknown as string;

      for (const gender of doiByAgegroupByGenderToDisplay) {
        const genderName = gender.dim2 as unknown as string;
        const foundGender = heatmapValues.find((v) => v.name === ts(`genders.${genderName}`));

        if (foundGender) {
          const foundData = foundGender.data.find((d) => d.x === ageGroupName);

          if (!foundData) {
            foundGender.data.push({
              x: ts(`genders.${ageGroupName}`),
              y: +gender.value.toFixed(2),
            });
          } else {
            foundData.y += +gender.value.toFixed(2);
          }
        } else {
          const heatmapValuesData = heatmapLabels.map((label) => {
            const y = label === ageGroupName ? +gender.value.toFixed(2) : 0;

            return {
              x: label,
              y,
            };
          });

          heatmapValues.push({
            name: ts(`genders.${genderName}`),
            data: heatmapValuesData,
          });
        }
      }
    }

    return [...heatmapValues].sort((a, b) => b.name.localeCompare(a.name));
  }, [heatmapLabels, kpis, ts]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h3" variant="mainBold">
          {ts("title")}
        </Typography>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={ts("title").toLowerCase()}
          labels={heatmapLabels}
          rotate={45}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
