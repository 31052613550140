import { ROUTE_CONFIG } from "@/app/routes/config";
import { PermissionRoles } from "@/enum/permissions";
import i18n from "@app/i18n";

export const routes = {
  user: [
    {
      path: ROUTE_CONFIG.EVENTS,
      text: i18n.t("navigation.events"),
      permission: PermissionRoles.GET_EVENTS,
    },
    {
      path: ROUTE_CONFIG.PROJECT_REPORTING,
      text: i18n.t("navigation.projectReporting"),
      permission: PermissionRoles.LIST_PROJECTS,
    },
    {
      path: ROUTE_CONFIG.USERS,
      text: i18n.t("navigation.users"),
      permission: PermissionRoles.GET_USER,
    },
    {
      path: ROUTE_CONFIG.BRANDS,
      text: i18n.t("navigation.brands"),
      permission: PermissionRoles.GET_BRANDS,
    },
    {
      path: ROUTE_CONFIG.MODULES,
      text: i18n.t("navigation.modules"),
      permission: PermissionRoles.GET_MODULES,
    },
    {
      path: ROUTE_CONFIG.DATA_SOURCES,
      text: i18n.t("navigation.dataSources"),
      permission: PermissionRoles.GET_DATA_SOURCE,
    },
  ],
  bSureAdmin: [
    {
      path: ROUTE_CONFIG.COMPANIES,
      text: i18n.t("navigation.companies"),
      permission: PermissionRoles.GET_COMPANY,
    },
  ],
  externalLink: [
    {
      text: i18n.t("navigation.cockpit"),
    },
  ],
  common: [
    {
      path: ROUTE_CONFIG.LOGOUT,
      text: i18n.t("navigation.logout"),
    },
  ],
};
