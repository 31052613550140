import { ReactNode } from "react";

import { api } from "@/app/redux/api";

import {
  ProjectByBrandKPIsResponseSchema,
  ProjectByBrandKPIsResponseType,
  ProjectType,
} from "../pages/ProjectReporting/project.schema";

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectsList: builder.query<
      { data: ProjectType[] },
      { companyId?: number; additionalData?: string }
    >({
      query: (params) => ({
        url: "projects/list",
        params: {
          ...(params && { ...params }),
        },
      }),
    }),
    getProjectByBrandKpi: builder.query<
      ProjectByBrandKPIsResponseType,
      { brandId: number; projectId: number; dashboard: ReactNode }
    >({
      query: ({ brandId, projectId, dashboard }) => ({
        url: "kpi",
        params: { brandId, projectId, dashboard },
        responseSchema: ProjectByBrandKPIsResponseSchema,
      }),
    }),
  }),
});

export const { useGetProjectsListQuery, useGetProjectByBrandKpiQuery } = projectsApi;
